<template>
  <div
    class="popup_wrap"
    style="width:920px; height:500px;"
  >
    <button
      type="button"
      class="layer_close"
      @click="$emit('close')"
    >
      close
    </button>
    <div class="popup_cont">
      <form id="searchForm" @submit.prevent>
        <h1 class="page_title">화물 추적 일정 구독</h1><!--화물 추적 일정 구독 -->
        <div class="content_box">
          <table class="tbl_col mt10">
            <colgroup>
              <col style="width: 28%">
              <col style="width: 12%">
              <col style="width: 12%">
              <col style="width: 12%">
              <col style="width: 12%">
              <col style="width: 12%">
              <col style="width: 12%">
            </colgroup>
            <tbody>
              <tr>
                <th class="td_left_line">Booking No.</th>
                <td class="td_left_line" colspan="6">
                  <input type="text" v-model="regParams.bkgNo" id="bkgNo" :disabled="true">
                </td>
              </tr>
              <tr>
                <th class="td_left_line">Container No.</th>
                <td class="td_left_line" colspan="6">
                  <span v-for="c in cntrList" :key="c.cntrNo">
                    <input type="checkbox" :id="c.cntrNo" v-model="c.checked" true-value="Y" false-value="N" />
                    <label :for="c.cntrNo" class="mr20">{{ c.cntrNo }}</label>
                  </span>
                </td>
              </tr>
              <tr>
                <th class="td_left_line" rowspan="2"></th>
                <th class="td_left_line" colspan="3">POL PORT</th>
                <th class="td_left_line" colspan="3">POD PORT</th>
              </tr>
              <tr>
                <td class="td_left_line" colspan="3">
                  <input type="text" v-model="regParams.polPortCd" id="polPortCd" :disabled="true" style="text-align: center">
                </td>
                <td class="td_left_line" colspan="3">
                  <input type="text" v-model="regParams.podPortCd" id="polPortCd" :disabled="true" style="text-align: center">
                </td>
              </tr>
              <tr>
                <th class="td_left_line">전체 체크</th>
                <td class="td_left_line" colspan="3">
                  <input
                    class="levelChk"
                    id="polAll"
                    type="checkbox"
                    @change="polCheckAll($event)"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
                <td class="td_left_line" colspan="3">
                  <input
                    class="levelChk"
                    id="podAll"
                    type="checkbox"
                    @change="podCheckAll($event)"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
              </tr>
              <tr>
                <th class="td_left_line" rowspan="2">특정 시점 선택</th>
                <td>
                  <label for="GTOOBE">Empty Container Pickup</label>
                </td>
                <td>
                  <label for="GTIOBF">POL PORT Container Gate In</label>
                </td>
                <td>
                  <label for="LDGOBF">POL PORT Loading</label>
                </td>
                <td>
                  <label for="DISIBF">POD PORT Discharging</label>
                </td>
                <td>
                  <label for="GTOIBF">POD PORT Container Gate Out</label>
                </td>
                <td>
                  <label for="GTIIBE">Empty Container Return</label>
                </td>
              </tr>
              <tr>
                <td>
                  <input
                    class="levelChk"
                    id="GTOOBE"
                    v-model="regParams.level1"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
                <td>
                  <input
                    class="levelChk"
                    id="GTIOBF"
                    v-model="regParams.level2"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
                <td>
                  <input
                    class="levelChk"
                    id="LDGOBF"
                    v-model="regParams.level3"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
                <td>
                  <input
                    class="levelChk"
                    id="DISIBF"
                    v-model="regParams.level4"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
                <td>
                  <input
                    class="levelChk"
                    id="GTOIBF"
                    v-model="regParams.level5"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
                <td>
                  <input
                    class="levelChk"
                    id="GTIIBE"
                    v-model="regParams.level6"
                    type="checkbox"
                    true-value="Y"
                    false-value="N"
                  />
                </td>
              </tr>
              <tr>
                <!--                <th></th><td></td><td></td><td></td><td></td><td></td><td></td>-->
              </tr>
            </tbody>
          </table>
        </div>

        <!-- 닫기 -->
        <div class="mt10 text_center">
          <a
            class="button blue lg"
            href="#"
            style="margin-right: 5px"
            @click="saveSubscription"
          >
            {{ $t('msg.CSBK100.178') }}
          </a>
          <a
            class="button gray lg"
            href="#"
            @click.prevent="$emit('close')"
          >
            {{ $t('msg.SCHD010P040.046') }}
          </a>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import schedule from '@/api/rest/schedule/schedule'
import vslScheduleInfo from '@/api/rest/schedule/vslScheduleInfo'
import vslScheduleInfo2 from '@/api/rest/schedule/vslScheduleInfo2'
import cargo from '@/api/rest/trans/cargo'
import rfDg from '@/api/rest/trans/rfDg'
import commons from '@/api/services/commons'
import { rootComputed } from '@/store/helpers'
import { rfDataGmt } from '@/api/rf-gmt-info'
import moment from 'moment'
import trans from '@/api/rest/trans/trans'

export default {
  name: 'CargoTrackingSubscriptionPop',
  components: {},
  props: {
    parentInfo: {
      type: Object
     }
  },
  data: function () {
    return {
      regParams: {
        bkgNo: '',
        polPortCd: '',
        podPortCd: '',
        level1: 'N',
        level2: 'N',
        level3: 'N',
        level4: 'N',
        level5: 'N',
        level6: 'N',
        cntrNoLists: '',
        orgCntrNoLists: ''
      },
      cntrList: []
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    parentInfo () {
      this.init()
    }
  },
  async created () {
    await this.init()
  },
  mounted () {
  },
  methods: {
    async init () {
      this.regParams.bkgNo = this.parentInfo.bkgNo
      this.regParams.polPortCd = this.parentInfo.polPortCd
      this.regParams.podPortCd = this.parentInfo.podPortCd
      this.cntrList = this.parentInfo.cntrList
      this.getSubscription()
    },
    polCheckAll (e) {
      if (e.target.checked) {
        this.regParams.level1 = 'Y'
        this.regParams.level2 = 'Y'
        this.regParams.level3 = 'Y'
      } else {
        this.regParams.level1 = 'N'
        this.regParams.level2 = 'N'
        this.regParams.level3 = 'N'
      }
    },
    podCheckAll (e) {
      if (e.target.checked) {
        this.regParams.level4 = 'Y'
        this.regParams.level5 = 'Y'
        this.regParams.level6 = 'Y'
      } else {
        this.regParams.level4 = 'N'
        this.regParams.level5 = 'N'
        this.regParams.level6 = 'N'
      }
    },
    async saveSubscription () {
      if (this.regParams.level1 === 'N' &&
        this.regParams.level2 === 'N' &&
        this.regParams.level3 === 'N' &&
        this.regParams.level4 === 'N' &&
        this.regParams.level5 === 'N' &&
        this.regParams.level6 === 'N'
      ) {
        this.$ekmtcCommon.alertDefault('시점을 선택해 주세요.')
        return
      }

      let cntrArr = []
      this.cntrList.forEach(c => {
        if (c.checked === 'Y') {
          cntrArr.push(c.cntrNo)
        }
      })
      if (cntrArr.length === 0) {
        this.$ekmtcCommon.alertDefault('Container No를 선택해 주세요.')
        return
      }

      this.regParams.cntrNoLists = cntrArr.join(',')

      let succFlag = false
      await cargo.saveSubscription(this.regParams).then(res => {
        succFlag = true
      }).catch(() => {
        succFlag = false
      }).finally(() => {
        if (succFlag) {
          const TH = this
          this.$ekmtcCommon.alertCallback(this.$t('art.CMATK383'), () => {
            TH.$emit('close')
          })
        } else {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.004'))
        }
      })
    },
    async getSubscription () {
      await cargo.findSubscription(this.regParams.bkgNo).then(res => {
        console.log(res)
        let data = res.data
        let orgCntrNoArr = []
        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (data[i].cntrSubsMvnt === 'GTOOBE') this.regParams.level1 = data[i].subsYn
            if (data[i].cntrSubsMvnt === 'GTIOBF') this.regParams.level2 = data[i].subsYn
            if (data[i].cntrSubsMvnt === 'LDGOBF') this.regParams.level3 = data[i].subsYn
            if (data[i].cntrSubsMvnt === 'DISIBF') this.regParams.level4 = data[i].subsYn
            if (data[i].cntrSubsMvnt === 'GTOIBF') this.regParams.level5 = data[i].subsYn
            if (data[i].cntrSubsMvnt === 'GTIIBE') this.regParams.level6 = data[i].subsYn
            if (!orgCntrNoArr.includes(data[i].cntrNoLists)) {
              orgCntrNoArr.push(data[i].cntrNoLists)
            }
          }

          this.regParams.orgCntrNoLists = orgCntrNoArr.join(',')

          this.cntrList.forEach(c => {
            if (orgCntrNoArr.includes(c.cntrNo)) {
              c.checked = 'Y'
            }
          })
        }
      }).catch(() => {
        console.log('error')
      })
    }
  }
}
</script>
<style scoped>
.levelChk {
  display: inline-block;
  position: relative;
  min-width: 15px;
  min-height: 22px;
  line-height: 22px;
  cursor: pointer;
  word-break: keep-all;
  opacity: 1;
}

.popup_wrap {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}
</style>
